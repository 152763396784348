<template>
  <div>
    <el-row :gutter="5" style="margin:40px 0 10px 0;">
      <el-col :span="24"> 应用信息如下： </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        <img v-if="dataArray.osTypeId == 20 && dataArray.iconPath == ''" src="@/images/android-1.png" style="height: 150px; width: 150px" alt="">
        <img v-else-if="dataArray.osTypeId == 10 && dataArray.iconPath == ''" src="@/images/ios-1.png" style="height: 150px; width: 150px" alt="">
        <img v-else :src="dataArray.iconPath" alt="" style="height: 150px; width: 150px" />
        <p style="width:150px;text-align: center;">{{dataArray.appName}}</p>
      </el-col>
      <el-col :span="20">
        <p>包名：{{dataArray.packageName}}</p>
        <p class="p-style-text">操作系统：{{dataArray.osTypeId==20?'Android':'iOS'}}</p>
        <p class="p-style-text">大小：{{dataArray.appSizeStr}}</p>
        <p class="p-style-text">版本：{{dataArray.versionName}}</p>
        <p class="p-style-text">MD5：{{dataArray.md5}}</p>
        <p class="p-style-text">应用类别：{{dataArray.categoryName}}</p>
      </el-col>
    </el-row>
    <el-row :gutter="5">
      <el-col :span="20">
        <el-button type="primary" size="small" style="margin-top: 12px" @click="Gonext">下一步</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { dialogInfo },

  props: {
    dataArray: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  computed: {},
  methods: {
    Gonext(){
      this.$emit("Gonext")
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.p-style-text {
  margin: 8px 0;
}
</style>
