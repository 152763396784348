function ExitRoute(x, y,that) {
  if (x > 185) {
    console.log("超出左边菜单")
  } else {
    if (y <= 106) {
      console.log("点击应用商店")
      that.$router.push("App");
      that.$emit("goBackSelection")
    }
    if (y >= 106 && y <= 142) {
      console.log("点击应用类别")
      that.$router.push("AppCategory");
    }
    if (y >= 142 && y <= 178) {
      console.log("点击应用库")
      that.$router.push("AppLib");
    }
    if (y >= 178 && y <= 214) {
      console.log("点击策略配置")
    }
    if (y >= 214 && y <= 250) {
      console.log("点击应用策略")
      that.$router.push("AppPolicy");
    }
    if (y >= 250 && y <= 286) {
      console.log("点击威胁定义")
      that.$router.push("ThreatDefinition");
    }
    if (y >= 286 && y <= 322) {
      console.log("点击安全事件")
      that.$router.push("SecurityEvent");
    }
    if (y >= 322 && y <= 358) {
      console.log("sdk监控")
      that.$router.push("SDKMonitor");
    }
    if (y >= 358 && y <= 394) {
      console.log("封禁配置")
      that.$router.push("BannedConfig");
    }
    if (y >= 394 && y <= 430) {
      console.log("白名单")
      that.$router.push("WhiteList");
    }
    if (y >= 430 && y <= 466) {
      console.log("界面水印")
      that.$router.push("WaterConfig");
    }
    if (y >= 466 && y <= 502) {
      console.log("敏感词库")
      that.$router.push("SensitiveWords");
    }

    if (y >= 502 && y <= 538) {
      console.log("安全检测")
      // that.$router.push("ThreatDefinition");
    }
    if (y >= 538 && y <= 574) {
      console.log("应用检测")
      that.$router.push("Scan");
      that.$emit("goBackSelection")
    }
    if (y >= 574 && y <= 610) {
      console.log("检测分析")
      that.$router.push("ScanAnalysis");
    }
    if (y >= 610 && y <= 646) {
      console.log("检测等级")
      that.$router.push("ScanLevel");
    }
    if (y >= 646 && y <= 682) {
      console.log("安全加固")
      // that.$router.push("ThreatDefinition");
    }
    if (y >= 682 && y <= 718) {
      console.log("应用加固")
      that.$router.push("AppReinforce");
    }
    if (y >= 718 && y <= 754) {
      console.log("sdk加固")
      that.$router.push("SDKReinforce");
    }
    if (y >= 754 && y <= 790) {
      console.log("H5加固")
      that.$router.push("H5Reinforce");
    }
    if (y >= 790 && y <= 826) {
      console.log("证书管理")
      that.$router.push("CertificateManage");
    }
  }
  if (y < 70) {
    if(x > 200 && x < 325) {
      console.log("首页")
      that.$router.push('SourceHome')
    }
    if(x > 325 && x < 450) {
      console.log("用户")
      that.$router.push('User')
    }
    if(x > 450 && x < 575) {
      console.log("设备管理")
      that.$router.push('Device')
    }
    if(x > 575 && x < 700) {
      console.log("应用管理")
      // that.$router.push('App')
    }
    if(x > 700 && x < 825) {
      console.log("内容管理")
      that.$router.push('Document')
    }
    if(x > 825 && x < 950) {
      console.log("分配管理")
      that.$router.push('Delivery')
    }
    if(x > 950 && x < 1075) {
      console.log("安全监测")
      that.$router.push('Home2')
    }
    if(x > 1075 && x < 1200) {
      console.log("移动运维")
      that.$router.push('GDment')
    }
    if(x > 1200 && x < 1325) {
      console.log("报表管理")
      that.$router.push('DataReport')
    }
    if(x > 1325 && x < 1450) {
      console.log("访问管理")
      that.$router.push('GatewayResourceManagement')
    }
  }
}
export default ExitRoute