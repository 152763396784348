<template>
  <div style="margin-top: 20px">
    <el-row style="marginleft: 350px; margintop: 20px" :gutter="16">
      <el-col :span="24">
        <el-card shadow="never" style="width: 1000px">
          <el-row :gutter="16">
            <el-col :span="8">
              <span>应用名称：{{ dataArray.appName }}</span>
            </el-col>
            <el-col :span="12">
              <span class="span-text">应用ID：{{ dataArray.packageName }}</span>
            </el-col>
            <el-col :span="4">
              <span>版本：{{ dataArray.versionName }}</span>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="24" style="margin-top: 20px">
        <el-card shadow="never" style="width: 1000px">
          <div style="display: flex">
            <p style="width: 400px">检测状态：{{ textSucces }}</p>
            <span style="margin-left: 370px">
              <el-button
                :disabled="textSucces !== '检测成功'"
                @click="Gonext"
                size="small"
                :type="btnType"
                style="width: 100px"
                >重新检测</el-button
              >
            </span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div v-if="scanScore >= 0">
      <!-- PDF -->
      <el-row style="marginleft: 350px" :gutter="16">
        <el-col style="margin-top: 60px; width: 200px">
          <div id="Ding" style="width: 150px; height: 150px"></div>
        </el-col>
        <el-col style="width: 220px">
          <div
            id="main"
            style="width: 425px; height: 300px; margin-left: 25px"
          ></div>
        </el-col>
        <el-col style="width: 200px">
          <p>
            <el-button
              :disabled="textSucces !== '检测成功'"
              @click="report2pdf"
              size="small"
              :type="btnType"
              style="width: 100px; margin-top: 60px; margin-left: 370px"
              >PDF报告</el-button
            >
          </p>
          <p>
            <el-button
              @click="report2doc"
              :disabled="textSucces !== '检测成功'"
              size="small"
              :type="btnType"
              style="width: 100px; margin-top: 20px; margin-left: 370px"
              >DOC报告</el-button
            >
          </p>
        </el-col>
      </el-row>
      <el-row style="marginleft: 350px" :gutter="16">
        <el-col :span="24">
          <div
            v-if="high + medium + low == 0"
            style="width: 1000px; height: 80px; line-height: 80px"
          >
            <b
              >检测结果：APP目前处于<span style="color: #008000">合规状态</span
              >，共检测出{{ high + medium + low }}个问题</b
            >
          </div>
          <div v-else style="width: 1000px; height: 80px; line-height: 80px">
            <b
              >检测结果：APP目前处于<span style="color: #e84646">风险状态</span
              >，共检测出{{ high + medium + low }}个问题</b
            >
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  props: {
    dataArray: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dataObject: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      low: 0,
      high: 0,
      medium: 0,
      scanScore: 0,
      valueText: "",
      reportPath: "",
      colorS: "green",
      btnType: "primary",
      textSucces: "正在生成检测报告",
    };
  },
  mounted() {
    this.reportDowland();
    setTimeout(() => {
      if (this.high + this.medium + this.low > 0) {
        this.colorS = "#fc2f3d";
        this.valueText = "风险";
      } else {
        this.valueText = "合规";
        this.colorS = "green";
      }
      this.Ring(this.colorS, this.valueText); //饼图
      this.pieChart(); //饼图
    }, 100);
  },
  methods: {
    Ring(colorS, valueText) { //环形饼图
      var chartDom = document.getElementById("Ding");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "item",
        },
        color: colorS,
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
              normal: {// normal 是图形在默认状态下的样式
                show: true,
                position: "center",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                formatter: valueText, // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容，
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.high + this.medium + this.low + "",
          },
        ],
      };
      option && myChart.setOption(option);
    },
    pieChart() {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            label: {
              normal: {
                formatter: "{b} : ({d}%)",
              },
            },
            type: "pie",
            radius: "50%",
            data: [
              {
                value: this.high,
                name: "高危",
                itemStyle: { color: "#fc2f3d" },
              },
              {
                value: this.medium,
                name: "中危",
                itemStyle: { color: "#ff8d00" },
              },
              {
                value: this.low,
                name: "低危",
                itemStyle: { color: "#00c7ce" },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
    async reportDowland() {
      let params = {
        appId: this.dataArray.id,
      };
      const res = await this.$axios.post(
        "/httpServe/complianceScan/reportStatus",
        params,
        true
      );
      if (res.data.status == 0) {
        this.btnType = "info";
        this.textSucces = "正在生成检测报告";
        setTimeout(() => {
          this.reportDowland();
        }, 5000);
      }
      if (res.data.status == 1) {
        this.btnType = "info";
        this.textSucces = "正在生成检测报告";
        setTimeout(() => {
          this.reportDowland();
        }, 5000);
      }
      this.high = res.data.high;
      this.low = res.data.low;
      this.medium = res.data.medium;
      this.reportPath = res.data.reportPath;
      if (res.data.status == 3) {
        this.btnType = "primary";
        this.textSucces = "检测成功";
      }
    },
    async Gonext() {
      this.$emit("Gonext");
    },
    async report2pdf() {// 下载PDF
      let data = this.reportPath.split(",");
      let path = "";
      data.forEach((item) => {
        if (item.indexOf("pdf") !== -1) {
          path = item;
        }
      });
      var param = {
        path: path,
      };
      const s = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      await this.$axios.downloadFile(s.data);
    },
    async report2doc() {// 下载doc
      let data = this.reportPath.split(",");
      let path = "";
      data.forEach((item) => {
        if (item.indexOf("doc") !== -1) {
          path = item;
        }
      });
      var param = {
        path: path,
      };
      const s = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      await this.$axios.downloadFile(s.data);
    },
  },
};
</script>

