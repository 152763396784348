<template>
  <div class="content-box" style="width: 1200px; margin: 0 auto">
    <el-row>
      <el-col :span="20">
        <el-steps
          :space="500"
          :active="active"
          finish-status="success"
          simple
          style="margin-top: 20px"
        >
          <el-step title="1、基本信息"></el-step>
          <el-step title="2、检测中"></el-step>
          <el-step title="3、检测完成"></el-step>
        </el-steps>
      </el-col>
    </el-row>
    <essential
      @Gonext="Gonext"
      v-if="active == 0"
      :dataArray="dataArray"
      @sunline="sunline"
    ></essential>
    <under-detection :dataObject="dataArray" @goBackSelection="goBackSelection" @Gonext="GonextDetection" v-if="active == 1"></under-detection>
    <completion-detection
      :dataArray="dataArray"
      :dataObject="detection"
      @Gonext="Gonext"
      v-if="active == 2"
    ></completion-detection>
  </div>
</template>

<script>
import essential from "./component/essential.vue";
import UnderDetection from "./component/UnderDetection.vue";
import CompletionDetection from "./component/CompletionDetection.vue";
export default {
  components: { essential, UnderDetection, CompletionDetection },
  props: {
    dataArray: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created(){
    if(this.dataArray.complianceStatus == 1){ //检测中
        this.active = 1
    }
    if(this.dataArray.complianceStatus == -1){ //未检测
        this.active = 0
    }
    if(this.dataArray.complianceStatus == 2){
        this.active = 2
    }
    if(this.dataArray.complianceStatus == 3){
        this.active = 2
    }
  },
  data() {
    return {
      next: "",
      active: 0,
      detection:{},
      androidData: {},
    }
  },
  methods: {
    Gonext() {
      if (this.active++ > 1) this.active = 0;
    },
    GonextDetection(params){
        this.detection = params
        if (this.active++ > 1) this.active = 0;
    },
    goBackSelection() {
        this.$emit("goBackSelection")
    },
    sunline() {},
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  font-size: 14px;
}
::v-deep .el-step__head.is-success {
    color: #606266;
    border-color:#606266;;
}
::v-deep .el-step__title.is-process {
    color: #fff;
}
::v-deep .el-step__title.is-success {
    color: #606266;
}
::v-deep .el-step.is-simple .el-step__icon {
    background: #fff;
}
::v-deep .el-steps--simple {
    background: #1e89e5;
    color: #fff;
}
::v-deep .el-step.is-simple .el-step__arrow::before{
  width: 3px !important;
  background: #fff !important;
}
::v-deep .el-step.is-simple .el-step__arrow::after{
  width: 3px !important;
  background: #fff !important;
}
</style>