<template>
  <div
    style="color: #606266; width: 1000px; height: 600px; border: solid 1px #ccc"
  >
    <el-row style="z-index: 2">
      <el-col
        :span="6"
        style="position: relative; height: 599px; border-right: 1px solid #ccc"
      >
        <!-- 树形 -->
        <div style="height: 40px; background: #1e89e5">
          <h3>检测指标</h3>
        </div>
        <el-scrollbar style="width:250px;height: 500px">
          <div style="font-size: 12px">
            <ul>
              <li v-for="item in tableData" :key="item.id">
                <div style="display: flex; margin: 5px 0">
                  <span
                    style="
                      font-size: 14px;
                      cursor: pointer;
                      display: inline-block;
                      width: 10px;
                      margin-right: 10px;
                    "
                    v-if="item.children"
                    @click="toggle(item)"
                  >
                    <svg
                      v-if="!item.show"
                      t="1672838332647"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="10592"
                      width="200"
                      height="200"
                    >
                      <path
                        d="M128.759037 245.033739l383.064442 383.064442 388.096039-388.097062 0.033769 0.070608c13.138228-12.709463 31.024597-20.528546 50.730405-20.528546 40.377616 0 73.114205 32.736589 73.114205 73.115228 0 19.705807-7.820106 37.591153-20.530592 50.730405l0.035816 0.034792-438.681134 438.685227-0.035816-0.035816c-13.280468 13.780865-31.915897 22.3838-52.585659 22.3838-0.071631 0-0.107447 0-0.178055 0-0.072655 0-0.10847 0-0.146333 0-20.669762 0-39.341007-8.601912-52.621475-22.3838l-0.035816 0.035816L20.336676 343.425653l0.179079-0.179079c-12.565177-13.139252-20.313651-30.951943-20.313651-50.587142 0-40.378639 32.736589-73.115228 73.114205-73.115228C95.485213 219.544205 115.334283 229.432413 128.759037 245.033739z"
                        p-id="10593"
                        fill="#8a8a8a"
                      ></path>
                    </svg>
                    <svg
                      v-else
                      t="1672838375349"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="13147"
                      width="200"
                      height="200"
                    >
                      <path
                        d="M895.239939 778.964214 512.17652 395.899772 124.080481 783.996834l-0.033769-0.070608c-13.137205 12.709463-31.024597 20.530592-50.731428 20.530592-40.376593 0-73.111135-32.736589-73.111135-73.115228 0-19.705807 7.819083-37.591153 20.528546-50.730405l-0.035816-0.034792 438.681134-438.686251 0.034792 0.035816c13.281491-13.779841 31.915897-22.3838 52.586682-22.3838 0.071631 0 0.106424 0 0.178055 0 0.072655 0 0.10847 0 0.144286 0 20.669762 0 39.341007 8.603959 52.623521 22.3838l0.033769-0.035816 438.684204 438.683181-0.179079 0.180102c12.565177 13.140275 20.313651 30.950919 20.313651 50.588165 0 40.378639-32.736589 73.115228-73.114205 73.115228C928.513764 804.453748 908.664693 794.565541 895.239939 778.964214z"
                        p-id="13148"
                        fill="#bfbfbf"
                      ></path>
                    </svg>
                  </span>
                  <span
                    :title="item.name"
                    class="text-content"
                    @click="toggle(item)"
                    style="cursor: pointer"
                    >{{ item.name }}</span
                  >
                </div>
                <ul
                  style="margin-left: 20px"
                  v-show="!item.show"
                  v-if="item.children"
                >
                  <li v-for="i in item.children" :key="i.id">
                    <div style="display: flex">
                      <img
                        v-if="i.operation.result == 1"
                        style="
                          margin-top: 8px;
                          margin-right: 2px;
                          width: 15px;
                          height: 15px;
                        "
                        src="@/images/圆形 (1).png"
                        alt=""
                      />
                      <img
                        v-if="i.operation.result == 2"
                        style="
                          margin-top: 8px;
                          margin-right: 2px;
                          width: 15px;
                          height: 15px;
                        "
                        src="@/images/圆形 (2).png"
                        alt=""
                      />
                      <img
                        v-if="i.operation.result == 0"
                        style="
                          margin-top: 8px;
                          margin-right: 2px;
                          width: 15px;
                          height: 15px;
                        "
                        src="@/images/圆形.png"
                        alt=""
                      />
                      <p
                        class="text-style"
                        :title="i.name"
                        :class="{ active: currentIndex == i.newIndex }"
                        style="
                          width: 200px;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          margin: 1px 0;
                          color: #606266;
                          cursor: pointer;
                        "
                        @click="toggleTree(i, i.newIndex)"
                      >
                        {{ i.name }}
                      </p>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </el-scrollbar>
        <el-button
          type="primary"
          size="small"
          style="
            position: absolute;
            bottom: 40px;
            width: 100%;
            height:40px;
            margin-top: 12px;
          "
          @click="Gonext"
          >完成检测</el-button
        >
        <p
          style="
            font-size: 12px;
            line-height: 40px;
            text-align: center;
            position: absolute;
            background: #fff;
            bottom: 2px;
            height: 40px;
            width: 100%;
          "
        >
          检测完成后生成检测报告
        </p>
      </el-col>
      <!-- 右边 -->
      <el-col :span="18" style="font-size: 12px">
        <el-row class="ul-rows">
          <el-col :span="4">检测项：</el-col>
          <el-col :span="18">{{ formdataList.name }}</el-col>
        </el-row>
        <el-row class="ul-row">
          <el-col :span="4">检测内容：</el-col>
          <el-col :span="18">{{ formdataList.content.content }}</el-col>
        </el-row>
        <el-row class="ul-row">
          <el-col :span="4">风险等级：</el-col>
          <el-col :span="20" v-if="formdataList.level == 3">低</el-col>
          <el-col :span="20" v-if="formdataList.level == 2">中</el-col>
          <el-col :span="20" v-if="formdataList.level == 1">高</el-col>
        </el-row>
        <el-row class="ul-row">
          <el-col :span="4">检测结果：</el-col>
          <el-col :span="20">
            <el-radio-group v-model="ruleForm.radio" @change="changeEvent">
              <el-radio :label="'1'">未发现风险</el-radio>
              <el-radio :label="'2'">不合规</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row class="ul-row">
          <el-col :span="4">详情说明：</el-col>
          <el-col :span="20">
            <el-input
              @input="changeInput"
              type="textarea"
              :rows="6"
              resize="none"
              style="width: 420px"
              placeholder="请输入内容"
              v-model="formdataList.operation.explain"
            >
            </el-input>
          </el-col>
        </el-row>
        <el-row class="ul-row">
          <el-col :span="4"
            >佐证图片({{ fileList.length }}/4)：</el-col
          >
          <el-col :span="20" style="display: flex">
            <div style="display: flex">
              <div
                v-for="(item, index) in fileList"
                :key="index"
                style="
                  position: relative;
                  border: 1px dotted #ccc;
                  width: 80px;
                  height: 80px;
                "
              >
                <div
                  @mouseenter="bannerMouseenter(index)"
                  @mouseleave="bannerMouseleave(index)"
                >
                  <div
                    v-show="isbanner == index"
                    class="pic-box"
                    style="position: absolute"
                  >
                    <i
                      @click="handlePictureCardPreview(item)"
                      class="el-icon-zoom-in"
                    ></i>
                    <i @click="handleRemove(index)" class="el-icon-delete"></i>
                  </div>
                  <img style="width: 77px; height: 78px" :src="item" alt="" />
                </div>
              </div>
            </div>

            <div
              v-show="fileList.length < 4"
              style="border: 1px dotted #ccc; width: 80px; height: 80px"
            >
              <span
                style="
                  color: #ccc;
                  line-height: 70px;
                  margin-left: 30px;
                  font-size: 24px;
                "
                >+</span
              >
              <input
                class="file"
                type="file"
                id=""
                value=""
                accept=".jpg,.png"
                name="saveFile"
                @change="tirggerFile($event)"
              />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-left: 140px">
            <el-button
              style="margin-right: 10px"
              v-show="btnDisableSmall != formdataList.newIndex"
              size="small"
              @click="topClick"
              type="primary"
              >上一项</el-button
            >
            <el-button
              v-show="btnDisableBig != formdataList.newIndex"
              size="small"
              @click="botClick"
              type="primary"
              >下一项</el-button
            >
          </div>
        </el-row>
      </el-col>
    </el-row>
    <div v-show="flagFixedbox" @click="dumpLog($event)" class="fixed-box"></div>
    <el-dialog v-dialogDrag :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
const Base64 = require("js-base64").Base64;
import ExitRoute from "./mixin.js"
export default {
  props: {
    dataObject: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      ruleForm: {
        radio: "1",
        textarea: "",
      },
      defaultProps: {
        children: "children",
        label: "text",
      },
      formdataList: {
        content: {},
        id: "",
        level: "",
        name: "",
        type: "",
        operation: {
          result: 0, // 0-未选中 1-未发现风险 2-不合规
          explain: "",
          pictures: [],
          fileList: [],
        },
      },
      low: 0,
      high: 0,
      type: "",
      medium: 0,
      status: 0,
      newIndex: 0,
      dataForm: {},
      fileList: [],
      formArray: [],
      tableData: [],
      dataArray: [],
      querArray: {},
      currentIndex: 1,
      dialogImageUrl: "",
      dataStatusSen: [],
      showFlag: true,
      isbanner: false,
      flagUpload: true,
      flagFixedbox: true,
      btnDisableBig: true,
      dialogVisible: false,
      btnDisableSmall: true,
      imgUrl: vueConfig.jqUrl.slice(0, vueConfig.jqUrl.length - 1),
    };
  },
  watch: {
    currentIndex: {
      handler(newVal, oldVal) {
        this.forFunction(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (this.dataObject.complianceStatus == 1) {
      this.complianceScanSelect();
    } else {
      this.complianceScanInit();
    }
  },
  methods: {
    async complianceScanInit() {
      let params = {
        appId: this.dataObject.id,
      };
      const res = await this.$axios.post(
        "/httpServe/complianceScan/init",
        params,
        true
      );
      if (res.data == 1) {
        this.complianceScanSelect();
      }
    },
    async complianceScanSelect() {
      let params = {
        appId: this.dataObject.id,
      };
      const res = await this.$axios.post(
        "/httpServe/complianceScan/select",
        params,
        true
      );
      this.dataArray = res.data;
      this.$set(
        this.dataArray,
        "complianceContent",
        JSON.parse(this.dataArray.complianceContent)
      );
      for (
        let index = 0;
        index < this.dataArray.complianceContent.length;
        index++
      ) {
        if (
          this.type == "" ||
          this.type !== this.dataArray.complianceContent[index].type
        ) {
          this.type = this.dataArray.complianceContent[index].type;
          this.dataForm = {
            name: this.dataArray.complianceContent[index].type,
            children: [],
          };
        }
        if (this.type == this.dataArray.complianceContent[index].type) {
          if (!this.dataArray.complianceContent[index].operation) {
            this.dataArray.complianceContent[index].operation = {
              result: 0,
              explain: "",
              pictures: [],
              fileList: [],
            };
          }
          this.dataForm.children.push(this.dataArray.complianceContent[index]);
        }
        this.formArray.push(this.dataForm);
      }
      let map = new Map();
      for (let item of this.formArray) {
        if (!map.has(item.name)) {
          map.set(item.name, item);
        }
      }
      let tableDatas = [...map.values()];
      tableDatas.forEach((item) => {
        item.children.forEach((newItem) => {
          this.newIndex++;
          newItem.newIndex = this.newIndex;
        });
      });
      this.tableData = tableDatas;
      this.toggleTree(
        this.tableData[0].children[0],
        this.tableData[0].children[0].newIndex
      );
    },
    //保存
    async Gonext() {
      this.status = 0
      let data = [];
      this.low = 0;
      this.medium = 0;
      this.high = 0;
      this.tableData.forEach((item, index) => {
        item.children.forEach((newitem, newIndex) => {
          data.push(newitem);
          if (newitem.level == 3) {
            //低
            if (newitem.operation.result == 2) {
              this.low++;
            }
          }
          if (newitem.level == 2) {
            //中
            if (newitem.operation.result == 2) {
              this.medium++;
            }
          }
          if (newitem.level == 1) {
            //高
            if (newitem.operation.result == 2) {
              this.high++;
            }
          }
        });
      });
      let params = {
        content: JSON.stringify(data),
        status: this.status, //检测状态 -1-未检测 0-检测中 1-检测完成
        high: this.high, //高危
        medium: this.medium, //中危
        low: this.low, //低危
        appId: this.dataObject.id, //应用id
      };
      const res = await this.$axios.upload(
        "/httpServe/complianceScan/save",
        params,
        true
      );
      let dataObject = {
        appName: this.dataObject.appName,
        versionName: this.dataObject.versionName,
        packageName: this.dataObject.packageName,
        high: this.high, //高危
        medium: this.medium, //中危
        low: this.low, //低危
        appId: this.dataObject.id, //应用id
      };
      this.$emit("Gonext", dataObject);
    },
    handleRemove(val) {
      this.tableData.forEach((item, index) => {
        item.children.forEach((i, k) => {
          if (i.id == this.formdataList.id) {
            i.operation.pictures.splice(val, 1);
            this.fileList.splice(val, 1);
          }
        });
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file;
      this.dialogVisible = true;
    },
    toggle(item) {
      var idx = this.tableData.indexOf(item);
      this.$set(this.tableData[idx], "show", !item.show);
    },
    forFunction(val) {
      this.flagFixedbox = true;
      if (this.tableData.length > 0) {
        let numsmall = this.tableData[0].children[0].newIndex;
        let numbig =
          this.tableData[this.tableData.length - 1].children[0].newIndex;
        this.btnDisableSmall = numsmall;
        this.btnDisableBig = numbig;
        if (val > numbig) {
          this.currentIndex = numsmall;
        }
        if (val < numsmall) {
          this.currentIndex = numbig;
        }
      }
      this.tableData.forEach((item, index) => {
        item.children.forEach((i, k) => {
          if (i.newIndex == val) {
            this.formdataList = i;
          }
        });
      });
      this.toggleTreeSetioen(this.formdataList, this.formdataList.newIndex);
    },
    toggleTreeSetioen(item, id) {
      this.$nextTick(() => {
        this.formdataList = { ...item };
        this.$set(
          this.ruleForm,
          "radio",
          this.formdataList.operation.result + ""
        );
      });
      this.fileList = []
      if(this.formdataList.operation.pictures.length>0){
            this.formdataList.operation.fileList = []
            this.formdataList.operation.pictures.forEach(async (imgItem, imgIndex) => {
                var param = {
                type: "protected",
                path: imgItem,
                };
                const res = await this.$axios.post(
                "/httpServe/sysFile/fileDown",
                param,
                true
                );
                let url = Base64.decode(res.data);
                url = this.imgUrl + url;
                this.fileList.push(url)
            });
          }
      this.currentIndex = id;
    },
    toggleTree(item, id) {
      this.$nextTick(() => {
        this.formdataList = { ...item };
        this.$set(
          this.ruleForm,
          "radio",
          this.formdataList.operation.result + ""
        );
      });
      this.currentIndex = id;
    },
    topClick() {
      this.currentIndex--;
    },
    botClick() {
      this.currentIndex++;
    },
    tirggerFile(data) {
        let imgformat =data.target.files[0].type
        if(imgformat.indexOf('image/gif')!==-1) {
            this.$message.error("文件格式错误")
            return
        }
        if(imgformat.indexOf('image')==-1) {
            this.$message.error("文件格式错误")
        }else{
            this.uploadSucces(data.target.files[0]);
        }
    },
    async uploadSucces(val) {
      let formdata = new FormData();
      formdata.append("files", val);
      formdata.append("type", "protected");
      formdata.append("fileType", "img");
      formdata.append("moduleName", "protected");
      const res = await this.$axios.upload(
        "/httpServe/sysFile/fileUp",
        formdata,
        true
      );
      this.downline(res.data.data[0]);
    },
    async downline(path, data, img) {
      var param = {
        type: "protected",
        path: path,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      let urlPath = path;
      let url = Base64.decode(res.data);
      url = this.imgUrl + url;
      this.tableData.forEach((item, index) => {
        item.children.forEach((i, k) => {
          if (i.id == this.formdataList.id) {
            i.operation.pictures.push(urlPath);
            this.fileList.push(url);
          }
        });
      });
    },
    changeEvent(val) {
      this.tableData.forEach((item, index) => {
        item.children.forEach((i, k) => {
          if (i.id == this.formdataList.id) {
            i.operation.result = this.ruleForm.radio * 1;
          }
        });
      });
    },
    changeInput(val) {
      this.tableData.forEach((item, index) => {
        item.children.forEach((i, k) => {
          if (i.id == this.formdataList.id) {
            i.operation.explain = val;
          }
        });
      });
    },
    bannerMouseenter(index) {
      this.isbanner = index;
    },
    bannerMouseleave(index) {
      this.isbanner = 5;
    },
    dumpLog(e) {
      this.$confirm("是否保存该页面编辑内容？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "persdsd",
      })
        .then(async () => {
          this.status = -1;
          let data = [];
          this.low = 0;
          this.medium = 0;
          this.high = 0;
          this.tableData.forEach((item, index) => {
            item.children.forEach((newitem, newIndex) => {
              data.push(newitem);
              if (newitem.level == 1) {
                //低
                if (newitem.operation.result == 2) {
                  this.low++;
                }
              }
              if (newitem.level == 2) {
                //中
                if (newitem.operation.result == 2) {
                  this.medium++;
                }
              }
              if (newitem.level == 3) {
                //高
                if (newitem.operation.result == 2) {
                  this.high++;
                }
              }
            });
          });
          let params = {
            content: JSON.stringify(data),
            status: this.status, //检测状态 -1-未检测 0-检测中 1-检测完成
            high: this.high, //高危
            medium: this.medium, //中危
            low: this.low, //低危
            appId: this.dataObject.id, //应用id
          };
          const res = await this.$axios.upload(
            "/httpServe/complianceScan/save",
            params,
            true
          );
          let x = e.clientX
          let y = e.clientY
          ExitRoute(x,y,this)
          this.flagFixedbox = false;
        })
        .catch(() => {
          this.flagFixedbox = false;
        });
    },
  }
};
</script>
<style lang="scss" scoped>
.ul-rows {
  margin: 40px 0 20px 20px;
}
.ul-row {
  margin: 20px 0 20px 20px;
}
h3 {
  width: 100%;
  height: 40px;
  text-align: left;
  line-height: 40px;
  color: #fff;
  margin-left: 10px;
}
.span-inline {
  display: inline-block;
}
.el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
}
::v-deep .el-dialog {
  width: 650px !important;
  max-height: 600px;
  overflow: auto;
}
ul,
li {
  list-style: none;
}
.active {
  background: #ffe6b0;
}
.icon {
  font-size: 8px;
  margin-left: 5px;
}
.upload {
  font-size: 12px;
  margin-left: -40px;
  overflow: hidden;
  position: absolute;
}
.file {
  position: absolute;
  z-index: 9999;
  margin-left: -52px;
  width: 80px;
  font-size: 60px;
  opacity: 0;
  filter: alpha(opacity=0);
  margin-top: -5px;
}
.pic-box {
  z-index: 2022;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
}
.el-icon-zoom-in {
  color: #fff;
  margin: 30px 0 0 15px;
  cursor: pointer;
}
.el-icon-delete {
  color: #fff;
  margin: 30px 0 0 15px;
  cursor: pointer;
}
.text-content {
  display: block;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-style {
  height: 30px;
  line-height: 30px;
  width: 200px;
}
:v-deep .el-radio__label {
  font-size: 12px !important;
}
.fixed-box {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 80px !important;
  height: 80px !important;
}
::v-deep .el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
</style>